import { Link, LinkProps } from "react-router-dom";
import { ReactComponent as BrotherBetsLogo } from "src/assets/icons/logos/BB_logo_block_white.svg";
import { ReactComponent as CasinoIcon } from "src/assets/icons/nav/Dice.svg";
import { ReactComponent as PokerIcon } from "src/assets/icons/nav/Chip.svg";
import { ReactComponent as SlotsIcon } from "src/assets/icons/nav/Slots.svg";
import { ReactComponent as PromotionIcon } from "src/assets/icons/nav/Promos.svg";
import { ReactComponent as FacebookIcon } from "src/assets/icons/social/Facebook.svg";
import { ReactComponent as InstagramIcon } from "src/assets/icons/social/Instagram.svg";
import { ReactComponent as TwitterIcon } from "src/assets/icons/social/Twitter.svg";
import { NavLink } from "src/components/atoms";
import { NavBar } from "src/components/molecules";
import { useTranslation } from "react-i18next";
import { ReactNode } from "react";

const FooterTitle = ({ children }: { children: ReactNode }) => (
  <h4 className="font-bold text-greyscale-100">{children}</h4>
);

const FLink = (props: LinkProps) => (
  <Link
    {...props}
    className="text-greyscale-200 md:max-w-32 xl:max-w-none flex gap-2 items-center"
  >
    {props.children}
  </Link>
);

export const Footer = () => {
  const { t } = useTranslation();
  return (
    <footer className="flex flex-col bg-black px-6 py-10 xl:px-52 lg:px-24 md:py-20 gap-20">
      {/* Logo Get product updates button, NavBar, links, social and Second NavBar section */}
      <div className="flex flex-col md:flex-row 2xl:justify-between xl:gap-10 gap-12">
        {/* Logo and Get product updates button section */}
        <div className="flex flex-col gap-[66px] sm:px-44 md:px-0 justify-center">
          <div className="md:w-56 self-center">
            <BrotherBetsLogo width={"124px"} />
          </div>
          {/* <div className="flex flex-col gap-4">
            <p className="text-greyscale-100 text-sm w-56">
              {t("text_footer_join-community")}
            </p>
            <EmailSubmitField />
          </div> */}
        </div>
        {/* NavBar, links and social section */}
        <div className="flex flex-col items-start justify-center gap-[66px] sm:px-28 md:px-0 px-12">
          <NavBar className="justify-center items-center py-4 md:gap-8 gap-11 flex-wrap lg:flex-nowrap">
            {/* <Link to={"/games/live"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <LiveIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_live")}
              </NavLink>
            </Link> */}
            <Link to={"/games/casino"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <CasinoIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_casino")}
              </NavLink>
            </Link>
            <Link to={"/games/poker"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <PokerIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_poker")}
              </NavLink>
            </Link>
            <Link to={"/games/slots"}>
              <NavLink className="gap-2 group text-greyscale-200 hover:text-secondary-200 transition duration-300">
                <SlotsIcon className="text-greyscale-200 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_slots")}
              </NavLink>
            </Link>
            <span className="bg-greyscale-100 w-px h-6"></span>
            <Link to={"/under-construction"}>
              <NavLink className="gap-2 group text-tertiary-400 hover:text-secondary-200 transition duration-300">
                <PromotionIcon className="text-tertiary-400 group-hover:text-secondary-200 transition duration-300" />
                {t("button_header_promotions")}
              </NavLink>
            </Link>
          </NavBar>

          {/* 3.3 and 11.5 rem ensure that the social column won't overflow
          alone in edge cases */}
          <div className="flex xl:gap-x-40 lg:gap-x-[3.3rem] md:gap-x-[11.5rem] gap-20 gap-y-10 md:justify-normal justify-between flex-wrap xl:flex-nowrap">
            <div className="flex flex-col gap-6 text-sm">
              <FooterTitle>{t("text_about")}</FooterTitle>
              <FLink to={"/about-us"}>{t("button_about")}</FLink>
              <FLink to={"/responsible-gaming"}>
                {t("button_responsible")}
              </FLink>
              {/* <FLink to={"/affiliates"}>{t("button_affiliates")}</FLink> */}
            </div>

            <div className="flex flex-col gap-6 text-sm">
              <FooterTitle>{t("text_help")}</FooterTitle>
              {/* <FLink to={"/faqs"}>{t("button_faqs")}</FLink> */}
              <FLink to={"/contact-us"}>{t("button_contact")}</FLink>
            </div>

            <div className="flex flex-col gap-6 text-sm">
              <FooterTitle>{t("text_regulations")}</FooterTitle>
              <FLink to={"/terms-and-conditions"}>{t("button_terms")}</FLink>
              <FLink to={"/privacy"}>{t("button_privacy")}</FLink>
              <FLink to={"/aml-and-kyc"}>{t("button_aml")}</FLink>
            </div>

            <div className="flex flex-col gap-6 text-sm">
              <FooterTitle>{t("text_social")}</FooterTitle>
              <FLink to={"/under-construction"}>
                <FacebookIcon className="text-greyscale-100 w-6 h-auto" />{" "}
                {t("button_facebook")}
              </FLink>
              <FLink to={"/under-construction"}>
                <TwitterIcon className="text-greyscale-100 w-6 h-auto" />{" "}
                {t("button_twitter")}
              </FLink>
              <FLink to={"/under-construction"}>
                <InstagramIcon className="text-greyscale-100 w-6 h-auto" />{" "}
                {t("button_instagram")}
              </FLink>
            </div>
          </div>
        </div>
      </div>
      {/* Second NavBar section */}
      <div className="flex flex-col md:justify-between md:items-center gap-8 md:px-20 sm:px-28 px-12">
        {/* <div className="flex justify-between">
          <NavBar className="justify-center lg:gap-32 md:gap-8 gap-6 text-sm flex-col md:flex-row items-start md:items-center flex-wrap lg:flex-nowrap">
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_reviews")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_bonuses")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>
                {t("button_payment-methods")}
              </Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_learn")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("button_news")}</Link>
            </NavLink>
            <NavLink className="text-greyscale-100">
              <Link to={"/under-construction"}>{t("text_about")}</Link>
            </NavLink>
          </NavBar>
        </div> */}
        <hr className="w-full border-greyscale-200" />
        <h4 className="text-xs text-greyscale-200 self-center">
          2024 © BrotherBets. {t("text_all-rights")}
        </h4>
        <h3 className="text-greyscale-100 self-center">
          {process.env.REACT_APP_WEBSITE_CLIENT}{" "}
          {process.env.REACT_APP_WEBSITE_VER}
        </h3>
      </div>
    </footer>
  );
};

export default Footer;
