import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  Banner,
  CashbackBonusBanner,
  FreeSpinsBanner,
  NavBar,
  //WelcomeBonusBanner,
} from "src/components/molecules";
import {
  FAQ,
  MainContainer,
  GamesGridSlider,
  BannerSlider,
} from "src/components/organisms";
import { Button } from "src/components/atoms";
import { ReactComponent as HotGamesIcon } from "src/assets/icons/nav/hotGames.svg";
import { ReactComponent as PokerGamesIcon } from "src/assets/icons/nav/pokerGames.svg";
import { ReactComponent as CasinoGamesIcon } from "src/assets/icons/nav/casinoGames.svg";
import { ReactComponent as SlotsColouredIcon } from "src/assets/icons/nav/slotsGames.svg";
import { ReactComponent as SkeletonSlider } from "src/assets/icons/loaders/games_casino_popular.svg";
import { ReactComponent as SkeletonSliderFilter } from "src/assets/icons/loaders/tabs_filterGames_slider.svg";
import GameModal from "src/components/molecules/gameModal/GameModal.component";
import { motion } from "framer-motion";
import ReactGA from "react-ga4";
import useNotificationsService from "src/services/notificationsService";
import { GameResponse } from "src/types";
import GamesSidebar from "src/components/molecules/gamesSidebar/GamesSidebar.component";
import useGamesService from "src/services/gamesService";
import Favourifier from "src/components/organisms/favourifier/Favourifier.component";
import { SplideSlide } from "@splidejs/react-splide";

export default function HomePage() {
  const { t } = useTranslation();
  const { getByType } = useGamesService();
  const { addErrorNotification } = useNotificationsService();

  const [displayGames, setDisplayGames] = useState<GameResponse[]>([]);
  const [isSliderLoading, setIsSliderLoading] = useState<boolean>(true);
  const [selectedGame, setSelectedGame] = useState<GameResponse | null>(null);
  const [gameTypeId, setGameTypeId] = useState<string | undefined>("");

  const loadSliderGames = async (gameTypeId: string): Promise<void> => {
    setGameTypeId(gameTypeId);
    const mobile = window.innerWidth < 1024;
    try {
      setIsSliderLoading(true);
      const games = await getByType(gameTypeId, mobile);
      setDisplayGames(games);
    } catch (error: any) {
      addErrorNotification(t("error_fetching-games"));
      console.log("Failed to fetch games: ", error);
    } finally {
      setIsSliderLoading(false);
    }
  };

  const sliderFilters = (
    <div className="font-medium overflow-x-auto px-2 py-4">
      <NavBar
        orientation="horizontal"
        className="items-center text-primary-400 gap-3.5 text-base py-10 inline-flex flex-wrap"
      >
        <Button
          key={0}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-full md:w-56 ${
            gameTypeId === "hot" ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => loadSliderGames("hot")}
        >
          <HotGamesIcon />
          {t("link_hot-games")}
        </Button>
        <Button
          key={1}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-full md:w-56 ${
            gameTypeId === "poker" ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => loadSliderGames("poker")}
        >
          <PokerGamesIcon />
          {t("link_poker-games")}
        </Button>
        <Button
          key={2}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl w-full md:w-56 ${
            gameTypeId === "casino" ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => loadSliderGames("casino")}
        >
          <CasinoGamesIcon />
          {t("link_casino-games")}
        </Button>
        <Button
          key={3}
          className={`flex items-center gap-3.5 p-3.5 bg-[#F7F7F7] border border-transparent rounded-xl  w-full md:w-56 ${
            gameTypeId === "slots" ? "opacity-100" : "opacity-30"
          }`}
          onClick={() => loadSliderGames("slots")}
        >
          <SlotsColouredIcon />
          {t("button_header_slots")}
        </Button>
      </NavBar>
    </div>
  );

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Home Page",
  });

  useEffect(() => {
    (async () => await loadSliderGames(""))();
  }, []);

  return (
    <MainContainer onSearchResultItemClick={setSelectedGame}>
      <Favourifier onLoad={setDisplayGames} games={displayGames} />
      {/* Main section */}
      <div className="flex 2xl:px-52 xl:px-20 px-4 bg-custom-gradient w-full">
        <div className="flex gap-10 md:py-12 py-6 w-full">
          <div className="hidden lg:block">
            <GamesSidebar />
          </div>
          <div className="flex flex-col gap-10 w-full">
            <GameModal
              selectedGame={selectedGame}
              onClose={() => setSelectedGame(null)}
            />
            <motion.div
              initial={{ y: 50, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              transition={{ duration: 0.7 }}
              className="flex flex-col gap-10"
            >
              <BannerSlider>
                <SplideSlide>
                  <FreeSpinsBanner isButtonVisible={true} />
                </SplideSlide>
                {/* <SplideSlide>
                  <WelcomeBonusBanner isButtonVisible={true} />
                </SplideSlide> */}
                <SplideSlide>
                  <CashbackBonusBanner isButtonVisible={true} />
                </SplideSlide>
              </BannerSlider>
            </motion.div>
            <div className="2xl:max-w-[calc(100vw-43.5rem)] xl:max-w-[calc(100vw-23rem)] lg:max-w-[calc(100vw-19.5rem)]">
              {!isSliderLoading ? (
                <GamesGridSlider
                  sliderId="games-slider"
                  sliderHeaderContent={sliderFilters}
                  contentList={displayGames}
                  onSelectGame={setSelectedGame}
                />
              ) : (
                <div className="flex flex-col gap-10">
                  <SkeletonSliderFilter />
                  <SkeletonSlider />
                </div>
              )}
            </div>
            <div className="flex flex-col gap-10 md:hidden">
              <Banner
                className="md:flex-row flex-col"
                image="images/banner2.png"
                background="images/banner2_bg.png"
                size="w-full h-[400px]"
                buttonOnClick={() => alert("Banner 2")}
                text={
                  <div className="flex flex-col md:gap-2 gap-3.5 items-center md:items-start">
                    <span className="text-white text-[34px] font-normal">
                      {t("banner2_header1")}
                    </span>
                    <span className="text-white text-[34px] font-bold">
                      {t("banner2_header2")}
                    </span>
                  </div>
                }
              />
              <Banner
                className="flex-row"
                image="images/banner3.png"
                background="images/banner3_bg.png"
                size="w-full h-[234px]"
                text={
                  <div className="flex flex-col md:gap-2 gap-3.5 items-center md:items-start">
                    <span className="text-white text-[34px] font-normal">
                      {t("banner3_header1")}
                    </span>
                    <span className="text-white text-[34px] font-bold">
                      {t("banner3_header2")}
                    </span>
                  </div>
                }
              />
            </div>
            <div className="md:px-24">
              <FAQ />
            </div>
            <section className="flex flex-col md:px-64 md:py-20 text-sm items-center text-center text-greyscale-100 gap-8">
              <b>{t("text_gambling-aware-2")}</b>
              <p>{t("text_copyright")}</p>
            </section>
          </div>
        </div>
      </div>
    </MainContainer>
  );
}
