import { MainContainer } from "src/components/organisms";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { ReactComponent as AddIcon } from "src/assets/icons/validation/Add.svg";
import { ReactComponent as CloseIcon } from "src/assets/icons/validation/Remove.svg";
import { FreeSpinsBanner, MarkdownContainer } from "src/components/molecules";
import { motion } from "framer-motion";

export default function FreeSpinsBonusPage() {
  const { t } = useTranslation();
  const [isTermsOpen, setIsTermsOpen] = useState(false);
  const toggleTerms = () => setIsTermsOpen(!isTermsOpen);

  return (
    <MainContainer>
      <div className="w-full h-full flex flex-col items-center bg-custom-gradient">
        <FreeSpinsBanner />
        <div className="flex flex-col items-center text-center mx-6 md:mx-auto">
          <h1 className="mt-[90px] md:text-[64px] text-5xl font-bold leading-[70px] text-greyscale-100">
            {t("text_welcome")}
          </h1>
          <h2 className="md:w-[480px] mt-[34px] text-[28px] leading-9 text-greyscale-100">
            To enjoy your free spins please follow the steps below
          </h2>
        </div>
        <div className="mt-[74px] flex md:w-[489px] p-[34px] flex-col items-center gap-[24px] rounded-[10px] border border-3 border-secondary-200 mx-6 md:mx-auto">
          {/* <p className="text-[40px] leading-[48px] text-greyscale-100">
            {t("text_claim-click")}
          </p>
          <Button
            className="flex justify-center items-center px-5 py-3.5 gap-4 bg-secondary-200 text-sm leading-6 text-greyscale-300 border border-secondary-200 rounded hover:bg-secondary-300 hover:border-secondary-300 hover:text-greyscale-100 group transition duration-300"
            key={"sign-up-button"}
            onClick={() =>
              token ? claimBonus("WELCOME_BONUS_FTD") : navigate("/login")
            }
            type="button"
          >
            <span className="whitespace-nowrap">{t("text_welcome-bonus")}</span>
          </Button> */}
          <ol>
            <li className="text-[28px] leading-9 text-greyscale-100 flex gap-1">
              <span className="text-secondary-200 w-8">1.</span>Register
            </li>
            <li className="text-[28px] leading-9 text-greyscale-100 flex gap-1">
              <span className="text-secondary-200 w-8">2.</span>Deposit
            </li>
            <li className="text-[28px] leading-9 text-greyscale-100 flex gap-1">
              <span className="text-secondary-200 w-8">3.</span>Play a game
            </li>
          </ol>
          <p className="text-greyscale-100">
            <span className="text-secondary-200">*</span> Your bonus will be
            attributed in between 15 minutes to the next 24 hours.
          </p>
        </div>
        <div className="w-full max-w-lg mx-6 md:mx-auto p-4 mt-[94px] mb-[130px]">
          <button
            onClick={toggleTerms}
            className="flex justify-between items-center w-full text-left"
          >
            <span className="text-greyscale-100 text-[28px] leading-9">
              {t("button_terms-conditions")}
            </span>
            <div className="border border-secondary-100 text-secondary-100 p-1 rounded-md">
              {isTermsOpen ? <CloseIcon /> : <AddIcon />}
            </div>
          </button>
          {isTermsOpen && (
            <motion.div
              initial={{ opacity: 0, height: 0 }}
              animate={{ opacity: 1, height: "auto" }}
              exit={{ opacity: 0, height: 0 }}
              transition={{ duration: 0.3 }}
              className="mt-10 text-greyscale-100 text-sm"
            >
              <MarkdownContainer mdNamespace="free-spins" forceScroll={false} />
            </motion.div>
          )}
        </div>
      </div>
    </MainContainer>
  );
}
