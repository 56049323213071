import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { Outlet, useLocation } from "react-router-dom";
import withScroll, { ScrollProps } from "src/higherOrderComponents/withScroll";
import useNotificationsService from "src/services/notificationsService";
import { useAuth } from "./AuthContext";
import { Store } from "react-notifications-component";

interface ScrollContextProps {
  // skips the next scroll. use it e.g. before invoking navigate()
  // so it keeps the page position
  setSkipNext: (skipNext: boolean) => void;
}

const ScrollContext = createContext<ScrollContextProps>({
  setSkipNext: () => {},
});

// deliberately outside the function component. DO NOT define this inside the
// FC, or it will break skipNext with excessive re-renders
const Scroll = withScroll((props: ScrollProps) => <>{props.children}</>);

// scroll provider to avoid prop drilling just to skip the scrolling as needed
export const ScrollProvider: React.FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const { addContinuousInfoNotification } = useNotificationsService();
  const { playerData } = useAuth();

  const [skipNext, setSkipNext] = useState(false);

  useEffect(() => {
    if (playerData && !playerData?.email_verified) {
      Store.removeNotification("info_verify-email");
      // Trigger a notification on page change
      addContinuousInfoNotification(
        "info_verify-email",
        t("info_verify-email", { email: playerData?.email })
      );
    } else {
      Store.removeNotification("info_verify-email");
    }
  }, [location]);

  return (
    <ScrollContext.Provider value={{ setSkipNext }}>
      {children}
      <Outlet />
      <Scroll skipNext={skipNext} />
    </ScrollContext.Provider>
  );
};

export const useScroll = (): ScrollContextProps => {
  const context = useContext(ScrollContext);
  if (!context) {
    throw new Error("useScroll must be used within a ScrollProvider");
  }
  return context;
};
