import { Form, Formik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import ReactGA from "react-ga4";
import {
  PersonalDetailsForm,
  AccountDetailsForm,
  ContactDetailsForm,
  DepositLimitsForm,
  LoginContainer,
} from "src/components/organisms";
import usePlayerService from "src/services/playerService";
// import useTransactionService from "src/services/transactionService";
import useNotificationsService from "src/services/notificationsService";
import { CreatePlayerRequest, WalletCurrency } from "src/types";
import { initialValues } from "src/utils/registerConstants";
import { isEmailValid } from "src/utils/emailFunctions";
import useRegisterService from "src/services/registerService";
import { useTranslation } from "react-i18next";

declare global {
  interface Window {
    xtremepush: any; // Add typing for xtremepush if there's no official typing
    _env_: any; // allow override of env vars during boot
  }
}

export default function RegisterPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [registrationStep, setRegistrationStep] = useState<number>(1);
  const [isTransitioningExit, setIsTransitioningExit] =
    useState<boolean>(false);
  const [isTransitioningEntry, setIsTransitioningEntry] =
    useState<boolean>(false);
  const [chosenCurrency, setChosenCurrency] = useState<WalletCurrency>("EUR");

  const { isEmailTaken, isNicknameTaken } = usePlayerService();
  // NOTE: For now, only Euros will be supported.
  // const { getCurrencies } = useTransactionService();
  const { addErrorNotification, addSuccessNotification } =
    useNotificationsService();
  const { register } = useRegisterService();

  const codeLength = 4;
  const transitionDuration = 300;

  const signUpSchema = Yup.object().shape({
    username: Yup.string()
      .max(20, t("error_max_20-chars"))
      .test("username", t("error_user-already-exists"), async (value) => {
        if (!value) return true;
        try {
          const exists = await isNicknameTaken(value);
          return !exists;
        } catch (error) {
          addErrorNotification(t("error_user-check"));
          console.error("Error checking username: ", error);
        }
        return true;
      }),
    firstName: Yup.string()
      .max(15, t("error_max_15-chars"))
      .required(t("error_required")),
    lastName: Yup.string()
      .max(20, t("error_max_20-chars"))
      .required(t("error_required")),
    gender: Yup.string().required(t("error_required")),
    birthDate: Yup.date().required(t("error_required")),
    phoneNumber: Yup.string().max(20, t("error_max_20-chars")),
    country: Yup.string()
      .max(20, t("error_max_20-chars"))
      .required(t("error_required")),
    address1: Yup.string().max(20, t("error_max_20-chars")),
    address2: Yup.string().max(20, t("error_max_20-chars")),
    zipCode: Yup.string().max(20, t("error_max_20-chars")),
    email: Yup.string().test(
      "email",
      t("error_email-in-use"),
      async (value, { createError }) => {
        if (!value) {
          return createError({ message: t("error_required") });
        } else {
          if (!isEmailValid(value)) {
            return createError({ message: t("error_invalid-email") });
          } else {
            try {
              const exists: boolean = await isEmailTaken(value);
              return !exists;
            } catch (error) {
              addErrorNotification(t("error_email-check"));
              console.error("Error checking email: ", error);
            }
          }
        }
        return true;
      }
    ),
    password: Yup.string()
      .min(6, t("error_min_6-chars"))
      .required(t("error_required")),
    code: Yup.string().max(codeLength, t("error_max_4-chars")),
    areaCode: Yup.string().max(20, t("error_max_20-chars")),
    dailyLimit: Yup.number()
      .required(t("error_required"))
      .max(99999, t("error_max-daily-limit"))
      .min(1, t("error_min-daily-limit")),
    weeklyLimit: Yup.number()
      .required(t("error_required"))
      .max(99999, t("error_max-weekly-limit"))
      .min(1, t("error_min-weekly-limit")),
    monthlyLimit: Yup.number()
      .required(t("error_required"))
      .max(99999, t("error_max-monthly-limit"))
      .min(1, t("error_min-monthly-limit")),
  });

  const stepSubtitle = [
    "",
    t("page_register_step1"),
    t("page_register_step2"),
    t("page_register_step3"),
  ];

  const handleNext = () => {
    window.scrollTo(0, 0);
    setIsTransitioningExit(true);

    setTimeout(() => {
      setRegistrationStep((prevStep) => prevStep + 1);
      setIsTransitioningExit(false);
      setIsTransitioningEntry(true);
      setTimeout(() => {
        setIsTransitioningEntry(false);
      }, 100);
    }, transitionDuration);
  };

  const handleBack = () => {
    setRegistrationStep((prevStep) => prevStep - 1);
  };

  const onSubmit = async (values: any, { setSubmitting }: any) => {
    if (
      parseInt(values.dailyLimit) >= parseInt(values.weeklyLimit) ||
      parseInt(values.weeklyLimit) >= parseInt(values.monthlyLimit)
    ) {
      addErrorNotification(t("error_limits"));
      return;
    }
    try {
      // Get user location and set currency
      // NOTE: For now, only Euros will be supported.
      /* const currencies = await getCurrencies();
      const currencyId = currencies.find(
        (currency) => currency.alphabetic_code === chosenCurrency
      )?.id; */

      // Format values to send to the API
      const createPlayerRequest: CreatePlayerRequest = {
        nickname: values.username,
        first_name: values.firstName,
        last_name: values.lastName,
        gender: values.gender,
        date_of_birth: values.birthDate,
        contact_number: values.phoneNumber,
        country: values.country,
        address_1: values.address1,
        address_2: values.address2,
        city: values.city,
        post_code: values.zipCode,
        email: values.email,
        password: values.password,
        currency_id: process.env.REACT_APP_CURRENCY_EUR, // NOTE: For now, only Euros will be supported.
        offer_email: values.offerEmail,
        offer_sms: values.offerSms,
        offer_bonus: values.offerBonus,
        twitter: values.twitter,
        instagram: values.instagram,
        document_number: values.documentNumber,
        initial_daily_limit: parseInt(values.dailyLimit),
        initial_weekly_limit: parseInt(values.weeklyLimit),
        initial_monthly_limit: parseInt(values.monthlyLimit),
      };
      await register(createPlayerRequest);
      navigate("/");
      window.xtremepush("tag", "registration_email", values.email);
      addSuccessNotification(t("success_player-registered"));
    } catch (error: any) {
      addErrorNotification(t("error_register"));
      console.error("Error registering player: ", error);
    }
    setSubmitting(false);
  };

  ReactGA.send({
    hitType: "pageview",
    page: window.location.pathname,
    title: "Register Page",
  });

  return (
    <LoginContainer lastStep={registrationStep === 3}>
      <Formik
        initialValues={initialValues}
        validationSchema={signUpSchema}
        onSubmit={onSubmit}
        validateOnBlur={false}
        validateOnChange={false}
      >
        {({
          handleBlur,
          handleChange,
          handleSubmit,
          errors,
          touched,
          values,
          setFieldValue,
          validateField,
        }) => (
          <div className="md:h-fit flex md:flex-row flex-col">
            <div
              className={`h-full flex md:pt-32 pt-2 md:pb-52 pb-8 justify-center text-primary-400 transition-all ease-in duration-${transitionDuration} ${
                registrationStep === 3 && !isTransitioningEntry
                  ? "md:w-1/2"
                  : "w-full"
              }`}
            >
              <div className="lg:w-[360px] w-[335px] h-full">
                <div className="flex flex-col gap-[34px]">
                  <div>
                    <span className="text-[38px] font-bold ">Registration</span>
                    <div className="flex justify-between text-[14px]">
                      <span>{stepSubtitle[registrationStep]}</span>
                      <span className="font-bold">
                        <span>{registrationStep} </span>
                        {t("text_out-of")} 3
                      </span>
                    </div>
                  </div>
                  <Form>
                    <div
                      className={`${
                        isTransitioningExit && registrationStep === 1
                          ? "opacity-0 -translate-x-60"
                          : "opacity-100"
                      } ${
                        registrationStep !== 1 ? "hidden" : ""
                      } transition-all ease-in duration-${transitionDuration}`}
                    >
                      <PersonalDetailsForm
                        values={values}
                        errors={errors}
                        onClickNext={() => handleNext()}
                        setDate={(date: string) =>
                          setFieldValue("birthDate", date)
                        }
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validateField={validateField}
                      />
                    </div>
                    <div
                      className={`transition-all ease-in duration-${transitionDuration} ${
                        registrationStep !== 2 && !isTransitioningEntry
                          ? "hidden"
                          : ""
                      } ${
                        isTransitioningEntry && registrationStep === 2
                          ? "opacity-0 translate-x-60"
                          : ""
                      } ${
                        !isTransitioningEntry && registrationStep === 2
                          ? "opacity-100"
                          : ""
                      } ${
                        isTransitioningExit && registrationStep === 2
                          ? "opacity-0 -translate-x-60"
                          : ""
                      } ${
                        isTransitioningEntry && registrationStep === 3
                          ? "hidden"
                          : ""
                      }`}
                    >
                      <AccountDetailsForm
                        values={values}
                        errors={errors}
                        onClickNext={() => handleNext()}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validateField={validateField}
                        onClickBack={handleBack}
                      />
                    </div>
                    <div
                      className={`transition-all ease-in duration-${transitionDuration} ${
                        registrationStep !== 3 && !isTransitioningEntry
                          ? "hidden"
                          : ""
                      } ${
                        isTransitioningEntry && registrationStep === 3
                          ? "opacity-0 translate-x-60"
                          : ""
                      } ${
                        !isTransitioningEntry && registrationStep === 3
                          ? "opacity-100"
                          : ""
                      } ${
                        isTransitioningExit && registrationStep === 3
                          ? "opacity-0 -translate-x-60"
                          : ""
                      } ${
                        isTransitioningEntry && registrationStep === 2
                          ? "hidden"
                          : ""
                      }`}
                    >
                      <ContactDetailsForm
                        values={values}
                        handleBlur={handleBlur}
                        handleChange={handleChange}
                        validateField={validateField}
                        onClickNext={() => handleNext()}
                        setFieldValue={setFieldValue}
                        setChosenCurrency={setChosenCurrency}
                        onClickBack={handleBack}
                      />
                    </div>
                  </Form>
                </div>
              </div>
            </div>
            {registrationStep === 3 && (
              <>
                <div
                  className={`transition-all ease-in-out duration-${transitionDuration} ${
                    registrationStep !== 3 && !isTransitioningEntry
                      ? "hidden"
                      : ""
                  } ${
                    isTransitioningEntry && registrationStep === 3
                      ? "opacity-0 -right-96"
                      : ""
                  } ${
                    !isTransitioningEntry && registrationStep === 3
                      ? "opacity-100 right-0"
                      : ""
                  } w-1/2 bg-loginDivide absolute -z-10 h-full -top-2 md:flex hidden`}
                />
                <div
                  className={`${
                    registrationStep !== 3 && !isTransitioningEntry
                      ? "hidden"
                      : ""
                  } ${
                    isTransitioningEntry && registrationStep === 3
                      ? "opacity-0 translate-x-1/2 w-0"
                      : ""
                  } ${
                    !isTransitioningEntry && registrationStep === 3
                      ? "opacity-100 md:w-1/2"
                      : ""
                  }
                  h-full md:bg-none bg-loginDivide flex md:pt-32 pt-10 md:pb-52 pb-12 justify-center transition-all ease-in duration-${transitionDuration}`}
                >
                  <DepositLimitsForm
                    errors={errors}
                    values={values}
                    touched={touched}
                    onClickNext={() => {
                      handleSubmit();
                    }}
                    setFieldValue={setFieldValue}
                    chosenCurrency={chosenCurrency}
                  />
                </div>
              </>
            )}
          </div>
        )}
      </Formik>
    </LoginContainer>
  );
}
