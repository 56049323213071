import { createBrowserRouter } from "react-router-dom";
import { PrivateRoute } from "src/components/atoms";
import AccountPage from "src/pages/AccountPage";
import VerificationPage from "src/pages/VerificationPage";
import AllGamesPage from "src/pages/AllGamesPage";
import GamesPage from "src/pages/GamesPage";
import HomePage from "src/pages/HomePage";
import LoginPage from "src/pages/LoginPage";
import NotFoundPage from "src/pages/NotFoundPage";
import RedirectClosePage from "src/pages/RedirectClosePage";
import RegisterPage from "src/pages/RegisterPage";
import ResetPasswordPage from "src/pages/ResetPasswordPage";
import TermsAndConditionsPage from "src/pages/TermsAndConditionsPage";
import UnderConstructionPage from "src/pages/UnderConstructionPage";
import WithdrawalPage from "src/pages/WithdrawalPage";
import MarkdownPage from "src/pages/MarkdownPage";
import { ScrollProvider } from "src/context/ScrollContext";
import ProfilePage from "src/pages/Account/ProfilePage";
import WalletPage from "src/pages/Account/WalletPage";
import BonusPage from "src/pages/Account/BonusPage";
import WelcomeBonusPage from "src/pages/Bonus/WelcomeBonusLandingPage";
import CashbackBonusPage from "src/pages/Bonus/CashbackBonusLandingPage";
import FreeSpinsBonusPage from "src/pages/Bonus/FreeSpinsBonusLandingPage";

const router = createBrowserRouter([
  {
    element: <ScrollProvider />,
    errorElement: <NotFoundPage />,
    children: [
      {
        path: "/",
        element: <HomePage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/games/:gameTypeId?/:gameId?",
        element: <GamesPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/all-games/:gameTypeId?/:gameId?",
        element: <AllGamesPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/register",
        element: <RegisterPage /* mcl={mcl}  */ />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/login",
        element: <LoginPage /* mcl={mcl}  */ />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/reset-password/:email/:resetToken",
        element: <ResetPasswordPage />,
        errorElement: <NotFoundPage />,
      },
      /* {
        path: "/affiliates",
        element: <TermsAndConditionsPage itemId="affiliates" />,
        errorElement: <NotFoundPage />,
      }, */
      /* {
        path: "/faqs",
        element: <MarkdownPage mdNamespace="faqs" />,
        errorElement: <NotFoundPage />,
      }, */
      {
        path: "/contact-us",
        element: <MarkdownPage mdNamespace="contact-us" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/terms-and-conditions",
        element: <TermsAndConditionsPage itemId="terms-and-conditions" />,
        errorElement: <NotFoundPage />,
      },
      /* {
        path: "/bonus-terms",
        element: <TermsAndConditionsPage itemId="bonus-terms" />,
        errorElement: <NotFoundPage />,
      }, */
      {
        path: "/cookie-policy",
        element: <TermsAndConditionsPage itemId="cookie-policy" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/banking",
        element: <TermsAndConditionsPage itemId="banking" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/responsible-gaming",
        element: <TermsAndConditionsPage itemId="responsible-gaming" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/privacy",
        element: <TermsAndConditionsPage itemId="privacy" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/aml-and-kyc",
        element: <TermsAndConditionsPage itemId="aml-and-kyc" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/under-construction",
        element: <UnderConstructionPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/account",
        element: <PrivateRoute minWidth={1024} fallbackPath="/" />,
        errorElement: <NotFoundPage />,
        children: [
          {
            path: "",
            element: <AccountPage />,
            errorElement: <NotFoundPage />,
            children: [
              {
                path: "",
                element: <ProfilePage isEditing={false} />,
                errorElement: <NotFoundPage />,
              },
              {
                path: "/account/edit",
                element: <ProfilePage isEditing={true} />,
                errorElement: <NotFoundPage />,
              },
              {
                path: "/account/wallet",
                element: <WalletPage />,
                errorElement: <NotFoundPage />,
              },
            ],
          },
        ],
      },
      {
        path: "/about-us",
        element: <MarkdownPage mdNamespace="about-us" />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/redirect-close",
        element: <RedirectClosePage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/withdrawal",
        element: <WithdrawalPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/verification/:email/:verificationToken",
        element: <VerificationPage />,
        errorElement: <NotFoundPage />,
      },
      /* {
        path: "/bonus/welcome-bonus",
        element: <WelcomeBonusPage />,
        errorElement: <NotFoundPage />,
      }, */
      {
        path: "/bonus/money-back",
        element: <CashbackBonusPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/bonus/free-spins",
        element: <FreeSpinsBonusPage />,
        errorElement: <NotFoundPage />,
      },
      {
        path: "/bonus/:bonusId",
        element: <NotFoundPage />, // Catch-all for invalid bonusId
      },
    ],
  },
]);

export default router;
